import * as React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { canonical } from '@helpers/alternates';

import useTranslation from '@hooks/useTranslation';

export default function JsonLd(): React.ReactElement {
    const { t } = useTranslation('pages/max/design2024/main/index');
    const { locale, pathname } = useRouter();
    const canonicalUrl = canonical(pathname, locale);
    const website = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'WebPage',
                '@id': canonicalUrl + '#webpage',
                url: canonicalUrl,
                name: t('seo.design2024.title'),
                description: t('seo.design2024.description'),
                potentialAction: [
                    {
                        '@type': 'ReadAction',
                        target: [canonicalUrl],
                    },
                ],
            },
        ],
    };

    return (
        <Head>
            <script
                id="website"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(website),
                }}
            />
        </Head>
    );
}
