import { cloneElement } from 'react';

import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import Article from '@components/global/Article';
import { Tab } from '@components/pages/max/TabsAccordion';
import { Tabs } from '@components/pages/max/TabsAccordion/Tabs/themed/default';
import DeskoptBr from '@components/ui/DeskoptBr';

import clsx from 'clsx';

import { CaseStudiesAllakando } from './Components/CustomerCaseStudy/Allakando';
import { PakoLorente } from './Components/CustomerCaseStudy/PakoLorente';
import { RedHot } from './Components/CustomerCaseStudy/RedHot';
import styles from './SectionIncreasedSales.module.css';

const ns = 'pages/max/design2024/components/SectionIncreasedSales/index';

type Content = {
    customTitle?: JSX.Element;
    customClass?: string;
};

const Content = ({ customTitle, customClass }: Content) => {
    const headerTitle = customTitle ? (
        customTitle
    ) : (
        <Trans
            i18nKey="sectionTitle"
            components={{
                span: <span />,
                br: <DeskoptBr />,
            }}
            ns={ns}
        />
    );

    return (
        <div className={clsx(styles['section-all-in-one-platform'], customClass)}>
            <Article
                align="center"
                className={styles['article-all-in-one-platform']}
                title={headerTitle}
                articleContent={
                    <>
                        <div>
                            <Tabs
                                type={'dots'}
                                arrows
                                classNames={styles}
                                tabDirection="row"
                                isArrowVisible
                            >
                                <Tab>
                                    <PakoLorente />
                                </Tab>
                                <Tab>
                                    <CaseStudiesAllakando />
                                </Tab>
                                <Tab>
                                    <RedHot />
                                </Tab>
                            </Tabs>
                        </div>
                    </>
                }
            />
        </div>
    );
};

interface SectionIncreasedSalesProps {
    container: React.ReactElement;
    customTitle?: JSX.Element;
    customClass?: string;
}

export default function SectionIncreasedSales({ container, customTitle, customClass }: SectionIncreasedSalesProps): JSX.Element {
    return (
        <section className={styles['section-increased-sales']}>
            {cloneElement(container, {
                children: (
                    <Content
                        customTitle={customTitle}
                        customClass={customClass}
                    />
                ),
                maxWidth: '1180px',
            })}
        </section>
    );
}
