import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import CustomTab from '@components/pages/max/design2024/SectionIncreasedSales/Components/CustomTab';
import Industry from '@components/pages/max/design2024/SectionIncreasedSales/Components/Industry';
import Link from '@components/pages/max/design2024/SectionIncreasedSales/Components/Link';
import Sales from '@components/pages/max/design2024/SectionIncreasedSales/Components/Sales';
import DeskoptBr from '@components/ui/DeskoptBr';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import { multisiteRouteCustomersPostRedHotMarketingCaseStudy } from '@static_components/multisiteLinks';

import styles from './RedHot.module.css';
import imgAVIF from './assets/img@1x.avif';
import imgPNG from './assets/img@1x.png';
import imgWebP1x from './assets/img@1x.webp';
import imgAVIF2x from './assets/img@2x.avif';
import imgPNG2x from './assets/img@2x.png';
import imgWebP2x from './assets/img@2x.webp';
import logo from './assets/logo.svg';

const ns = 'pages/max/design2024/components/SectionIncreasedSales/index';

export function RedHot() {
    const { t } = useTranslation(ns);
    const { locale } = useRouter();
    return (
        <CustomTab
            caseStudies={{
                logo: (
                    <picture>
                        <img
                            width={logo.width}
                            height={logo.height}
                            decoding="async"
                            loading="lazy"
                            alt=""
                            src={logo.src}
                        />
                    </picture>
                ),
                desc: (
                    <Trans
                        i18nKey="increasedSalesItem3BusinessDesc"
                        components={{
                            p: <p />,
                            br: <br />,
                        }}
                        ns={ns}
                    />
                ),
                link: (
                    <>
                        <Button
                            href={multisiteRouteCustomersPostRedHotMarketingCaseStudy(locale)}
                            label={t('increasedSalesItemsButtonLabel')}
                            color="ghost-blue"
                        />
                        <div className={styles.action}>
                            <Link
                                arrow={true}
                                label={t('linkLablelCustomersStories')}
                                customLink={<CustomLink href={{ pathname: 'customers' }} />}
                            />
                        </div>
                    </>
                ),
            }}
            picture={
                <picture className={styles.img}>
                    <source
                        type="image/avif"
                        srcSet={`${imgAVIF.src} 1x, ${imgAVIF2x.src} 2x`}
                    />
                    <source
                        type="image/webp"
                        srcSet={`${imgWebP1x.src} 1x, ${imgWebP2x.src} 2x`}
                    />
                    <source srcSet={`${imgPNG.src} 1x, ${imgPNG2x.src} 2x`} />
                    <img
                        width={imgPNG.width}
                        height={imgPNG.height}
                        decoding="async"
                        loading="lazy"
                        alt=""
                        src={imgPNG.src}
                    />
                </picture>
            }
            industry={
                <>
                    <Industry industry={t('increasedSalesItem3BusinessIndustryItems')} />
                </>
            }
            sales={
                <>
                    <Sales
                        number={
                            <Trans
                                i18nKey="increasedSalesItem3BusinessSalesItem1Number"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                        desc={
                            <Trans
                                i18nKey="increasedSalesItem3BusinessSalesItem1Desc"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                    />
                    <Sales
                        number={
                            <Trans
                                i18nKey="increasedSalesItem3BusinessSalesItem2Number"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                        desc={
                            <Trans
                                i18nKey="increasedSalesItem3BusinessSalesItem2Desc"
                                components={{
                                    span: <span />,
                                    br: <br />,
                                }}
                                ns={ns}
                            />
                        }
                    />
                </>
            }
        />
    );
}
